<template>
  <div>
    <h3 class="page-title">ئاساسىي تىزىملىك باشقۇرۇش</h3>
    <el-divider></el-divider>
    <!-- list -->
    <div v-if="showList">
      <el-row class="rtl-right">
        <el-button type="primary" plain icon="el-icon-circle-plus-outline" v-if="pageTags.create" @click="add">قوشۇش </el-button>
      </el-row>
      <div class="table-box" v-if="pageTags.list">
        <el-table
          :data="tableData" 
          border 
          style="width: 100%" 
          v-loading="loading">
          <el-table-column prop="image" label="رەسىم" width="150">
            <template slot-scope="scope">
              <img :src="scope.row.image" class="table-img">
            </template>
          </el-table-column>
          <el-table-column prop="name_ug" label="ئاساسىي تىزىملىك (ئۇيغۇرچە)"></el-table-column>
          <el-table-column prop="name_zh" label="ئاساسىي تىزىملىك (خەنزۇچە)"></el-table-column>
          <!-- <el-table-column prop="url" label="فىلىم ئادرېسى"></el-table-column> -->
          <el-table-column prop="order" label="تەرتىپى"></el-table-column>
          <el-table-column prop="home_page" width="120" label="تەۋسىيە">
            <template slot-scope="scope">
              <label v-show="scope.row.home_page==1">ھەئە</label>
              <label v-show="scope.row.home_page==0">ياق</label>
            </template>
          </el-table-column>
          <el-table-column prop="status" width="120" label="ھالەت">
            <template slot-scope="scope">
              <label v-show="scope.row.status==1">ئوچۇق</label>
              <label v-show="scope.row.status==0">ئېتىك</label>
            </template>
          </el-table-column>
          <el-table-column label="مەشغۇلات" prop="dosome" width="300">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                v-if="pageTags.update"
                @click="handleEdit(scope.$index, scope.row)">تەھرىرلەش </el-button>
              <el-button
                size="mini"
                type="danger"
                v-if="pageTags.delete"
                @click="handleDelete(scope.$index, scope.row)">ئۆچۈرۈش </el-button>
                <el-button
                  size="mini"
                  type="success"
                  v-if="scope.row.course_category_id==0"
                  @click="handleSub(scope.row)">تارماق تىزىملىك</el-button>
            </template>
          </el-table-column>
        </el-table> 
      </div>
    </div>

    <!-- subList -->
    <div v-if="!showList">
      <el-row class="rtl-right">
        <el-button type="primary" plain icon="el-icon-right" @click="backToList">قايتىش</el-button>
        <el-button type="primary" plain icon="el-icon-circle-plus-outline" v-if="pageTags.create" @click="add">تارماق تىزىملىك قوشۇش</el-button>
      </el-row>
      <el-divider>{{curGroup.name_ug}}</el-divider>
      <div class="table-box">
        <el-table
          :data="subTableData" 
          border 
          style="width: 100%" 
          v-loading="subLoading">
          <el-table-column prop="image" label="رەسىم" width="150">
            <template slot-scope="scope">
              <img :src="scope.row.image" class="table-img">
            </template>
          </el-table-column>
          <el-table-column prop="name_ug" label="تارماق تىزىملىك (ئۇيغۇرچە)"></el-table-column>
          <el-table-column prop="name_zh" label="تارماق تىزىملىك (خەنزۇچە)"></el-table-column>
          <!-- <el-table-column prop="url" label="فىلىم ئادرېسى"></el-table-column> -->
          <el-table-column prop="order" label="تەرتىپى"></el-table-column>
          <el-table-column prop="home_page" width="120" label="تەۋسىيە">
            <template slot-scope="scope">
              <label v-show="scope.row.home_page==1">ھەئە</label>
              <label v-show="scope.row.home_page==0">ياق</label>
            </template>
          </el-table-column>
          <el-table-column prop="status" width="120" label="ھالەت">
            <template slot-scope="scope">
              <label v-show="scope.row.status==1">ئوچۇق</label>
              <label v-show="scope.row.status==0">ئېتىك</label>
            </template>
          </el-table-column>
          <el-table-column label="مەشغۇلات" prop="dosome" width="300">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                v-if="pageTags.update"
                @click="handleEdit(scope.$index, scope.row)">تەھرىرلەش </el-button>
              <el-button
                size="mini"
                type="danger"
                v-if="pageTags.delete"
                @click="handleDelete(scope.$index, scope.row)">ئۆچۈرۈش </el-button>
            </template>
          </el-table-column>
        </el-table> 
      </div>
    </div>
    
    <!-- el-dialog -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="35%"
      :before-close="handleClose">
      <div class="rtl-right form-content">
        <el-form label-width="120px" :model="ruleForm" ref="ruleForm">
          <el-form-item label="رەسىم" prop="image">
            <el-upload
              class="avatar-uploader"
              :action="imgActionUrl"
              name="image"
              :data="imageData"
              :headers="imageHeader"
              :on-error="handleImageError"
              :on-success="handleImageSuccess">
              <img v-if="ruleForm.image" :src="ruleForm.image" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="ئاساسىي تىزىملىك (ئۇيغۇرچە)" prop="name_ug">
            <el-input v-model="ruleForm.name_ug" placeholder="ئاساسىي تىزىملىك (ئۇيغۇرچە)"></el-input>
          </el-form-item>
          <el-form-item label="ئاساسىي تىزىملىك (خەنزۇچە)" prop="name_zh">
            <el-input v-model="ruleForm.name_zh" class="ltr-left" placeholder="分类（中文）"></el-input>
          </el-form-item>
          <!-- <el-form-item label="فىلىم ئادرېسى" prop="url">
            <el-input v-model="ruleForm.url" class="ltr-left" placeholder="https:www.course.com/24"></el-input>
          </el-form-item> -->
          <el-form-item label="تەرتىپى" prop="order">
            <el-input type="number" v-model="ruleForm.order" class="ltr-left" placeholder="تەرتىپى"></el-input>
          </el-form-item>
          <el-form-item label="تەۋسىيە" prop="home_page" class="radio-form">
            <el-radio v-model="ruleForm.home_page" label="1">ھەئە</el-radio>
            <el-radio v-model="ruleForm.home_page" label="0">ياق</el-radio>
          </el-form-item>
          <el-form-item label="ھالەت" prop="status" class="radio-form">
            <el-radio v-model="ruleForm.status" label="1">ئوچۇق</el-radio>
            <el-radio v-model="ruleForm.status" label="0">ئېتىك</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" plain @click="submitForm('ruleForm')">مۇقىملاش</el-button>
        <el-button type="warning" plain @click="resetForm('ruleForm')">ئەسلىگە قايتۇرۇش</el-button>
        <el-button type="danger" plain @click="closeDialog('ruleForm')">بىكار قىلىش</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<style lang="scss" scoped>
  .avatar-uploader{
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 150px;
    height: 150px;
    img{
      width: 150px;
      height: 150px;
    }
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 24px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .table-img{
    width: 80px;
    height: 80px;
    border: 1px solid #EBEEF5;
  }
</style>

<script>
  var self;
  import baseConfig from '../base.config.js'

  export default {
    activated: function() {
      self = this;
      self.getList();
      self.imgActionUrl = baseConfig.imgActionUrl;
    },
    data() {
      return {
        tableData: [],
        loading: false,
        dialogVisible: false,
        ruleForm: {
          image:"",
          name_ug: "",
          name_zh: "",
          // url: "",
          order: "",
          home_page:"1",
          status: "1"
        },
        editIndex: -1,
        dialogTitle:"",
        addDialog:true,
        editId:"",
        pageTags:{
          create:true
        },
        imgActionUrl:"",
        imageData:{
          "folder":"test"
        },
        imageHeader:{
          'Authorization': 'Bearer ' + localStorage.getItem("token"),
          'X-Requested-With': 'XMLHttpRequest'
        },
        showList:true,
        curGroup:null,
        subLoading:false,
        subTableData:[]
      };
    },
    methods: {
      getList() {
        self.loading = true;
        self.$fetch("admin/course/category",{
          course_category_id:0
        }).then(response => {
          if (response.status == 200) {
           self.tableData = response.data.data;
          } else{
            console.log(response.message);
          }

          self.loading = false;
          if(JSON.parse(sessionStorage.getItem("curTags"))){
            self.pageTags = JSON.parse(sessionStorage.getItem("curTags"));
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
          self.loading = false;
        });
      },
      add() {
        self.addDialog = true;
        self.dialogTitle = "قوشۇش";
        self.dialogVisible = true;
      },
      handleEdit(index, row) {
        self.addDialog = false;
        self.dialogTitle = "تەھرىرلەش";
        self.editId = row.id;
        self.ruleForm.image = row.image;
        self.ruleForm.name_ug = row.name_ug;
        self.ruleForm.name_zh = row.name_zh;
        // self.ruleForm.url = row.url;
        self.ruleForm.order = row.order;
        self.ruleForm.home_page = row.home_page + '';
        self.ruleForm.status = row.status + '';
        self.dialogVisible = true;
      },
      handleDelete(index, row) {
        self.$confirm("ئۆچۈرۈشنى مۇقۇملامسىز ؟", "ئەسكەرتىش", {
          confirmButtonText: "مۇقىملاش",
          cancelButtonText: "بىكار قىلىش",
          type: "warning"
        }).then(() => {
          self.$remove('admin/course/category/'+row.id).then((response) => {
            console.log(response);
            if(response.status == 204){
              self.getList();
              if(self.curGroup){
                self.handleSub(self.curGroup);
              }
              self.$message({
                message: "ئۆچۈرۈش تاماملاندى",
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response);
            }
          }).catch(err => {
            console.log('××××××××××××××××');
            console.log(err);
          });

        }).catch(() => {
          self.$message({
            type: "info",
            message: "ئۆچۈرۈش بىكار قىلىندى"
          });
        });
      },
      handleImageSuccess(res, file) {
        self.ruleForm.image = res.data.image;
      },
      handleImageError(err,file){
        var uploadErr = JSON.parse(err.toString().replace('Error:',''));
        self.$message({
          message: uploadErr.message,
          type: 'warning'
        });
      },
      submitForm(formName) {
        var myData = {
          image: self.ruleForm.image,
          name_ug: self.ruleForm.name_ug,
          name_zh: self.ruleForm.name_zh,
          // url: self.ruleForm.url,
          order: self.ruleForm.order,
          home_page: self.ruleForm.home_page,
          status: self.ruleForm.status
        }
        if(self.curGroup){
          myData.course_category_id = self.curGroup.id;
        }

        if(self.addDialog){
          self.$post('admin/course/category',myData).then((response) => {
            if(response.status == 201){
              self.closeDialog(formName);
              self.getList();
              if(self.curGroup){
                self.handleSub(self.curGroup);
              }
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response.data.message);
            }
          }).catch(err => {
            console.log('××××××××××××××');
            console.log(err);
          });
        }else{
          self.$put('admin/course/category/'+self.editId,myData).then((response) => {
            if(response.status == 201){
              self.closeDialog(formName);
              self.getList();
              if(self.curGroup){
                self.handleSub(self.curGroup);
              }
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200
              });
            }else{
              self.$message({
                message: response.data.message,
                type: "warning",
                duration: 1500
              });
            }
          }).catch(err => {
            console.log('××××××××××××××');
            console.log(err);
          });
        }
      },
      resetForm(formName) {
        self.$refs[formName].resetFields();
        self.ruleForm = {
          image: "",
          name_ug: "",
          name_zh: "",
          // url: "",
          order: "",
          home_page: "1",
          status: "1"
        }
      },
      closeDialog(formName) {
        self.resetForm(formName);
        self.dialogVisible = false;
      },
      handleClose(done) {
        done();
        self.resetForm("ruleForm");
      },
      handleSub(row){
        self.curGroup = row;
        self.showList = false;

        self.subLoading = true;
        self.$fetch("admin/course/category",{
          course_category_id:self.curGroup.id
        }).then(response => {
          if (response.status == 200) {
           self.subTableData = response.data.data;
          } else{
            console.log(response.message);
          }
          self.subLoading = false;
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
          self.subLoading = false;
        });
      },
      backToList(){
        self.showList = true;
        self.curGroup = null;
      }
    },
    deactivated(){
      self.backToList();
    }
  };
</script>
